module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N99B8D5","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Domirent - Wypożyczalnia narzędzi","description":"Wypożyczamy narzędzia i sprzęt budowalny na doby.","short_name":"Domirent - Wypożyczalnia","start_url":"https://domirent-wypozyczalnia.pl","background_color":"#ffffff","theme_color":"#000000","display":"minimal-ui","lang":"pl","icon":"src/assets/domirent-logo.png","icons":[{"src":"/favicons/icon-32x32.png","sizes":"48x48","type":"image/png"},{"src":"/favicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/favicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/favicons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/favicons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/favicons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/favicons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7749c11d41d890c3c8bd7c8853337099"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
